<template>
  <span>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          outlined
          rounded
          v-bind="attrs"
          class="d-none"
          v-on="on"
        >
          Sign Up
        </v-btn>
      </template>
      <v-card class="rounded-xl">
        <v-card-title>
          <span class="text-h5">Sign Up</span>
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-8">
          <v-btn
            block
            center
            class="mb-4 rounded-xl"
            @click="googleLogin"
          >
            <v-icon class="mr-2">
              mdi-google
            </v-icon>

            Google
          </v-btn>
          <v-btn
            block
            center
            class="mb-4 rounded-xl"
            @click="twitterLogin"
          >
            <v-icon class="mr-2">
              mdi-twitter
            </v-icon>Twitter
          </v-btn>
          <div class="text-center font-weight-black">
            or
          </div>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="username"
                    label="Username"
                    :rules="usernameRules"
                    type="username"
                    required
                  />
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="emailRules"
                    :error-messages="emailMessages"
                    type="email"
                    required
                  />
                  <v-text-field
                    v-model="password"
                    label="Password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :error-messages="passwordMessages"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="checkbox"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    label="Do you agree?"
                    required
                  >
                    <template #label>
                      <div @click.stop="">
                        Do you accept our
                        <NuxtLink
                          to="terms"
                          target="_blank"
                          class="white--text text-cursor text-decoration-underline"
                        >
                          terms of use
                        </Nuxtlink>
                        and
                        <NuxtLink
                          to="privacy"
                          target="_blank"
                          class="white--text text-cursor text-decoration-underline"
                        >
                          privacy policy
                        </Nuxtlink>
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-btn
                block
                class="rounded-xl"
                @click="signUp"
              >
                Sign Up
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12">
              Already have an account? <span class="text-decoration-underline white--text cursor-pointer" @click="dialog = false; $root.$emit('eventFromBar')">Login</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="emailSentDialog"
      max-width="400px"
    >
      <v-card class="rounded-xl">
        <v-card-title>
          Congratulations!
          <v-spacer />
          <v-btn
            icon
            @click="emailSentDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="mt-4 text--primary">
            Your account has been created successfully.
          </p>
          <p class="mt-4 text--primary">
            Please check your {{ email }} email to confirm your account.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import login from "@/mixins/login";
import Auth from "@/services/auth";

export default {
  name: "SignIn",
  mixins: [login],
  data: () => ({
    dialog: false,
    emailSentDialog: false,
    checkbox: false,
    email: "",
    emailMessages: [],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    password: "",
    showPassword: false,
    passwordMessages: [],
    passwordRules: [
      v => !!v || "Password is required",
      v => v.length >= 10 || "Password must be at least 10 characters",
    ],
    username: "",
    usernameRules: [
      v => !!v || "Username is required",
      v => v.length <= 50 || "Name must be less than 50 characters",
    ],
  }),
  mounted () {
    this.$root.$on("eventFromLogin", () => {
      this.dialog = true;
    });
  },
  methods: {
    async signUp () {
      if (!this.$refs.form.validate()) {
        return;
      }
      const authAPI = new Auth(this.$axios);
      await authAPI.signup(
        this.username,
        this.email,
        this.password,
      ).then(async () => {
        const authAPI = new Auth(this.$axios);
        await authAPI.sendEmailVerification(
          this.username,
        ).then(() => {
          this.dialog = false;
          this.emailSentDialog = true;
        });
      }).catch((error) => {
        switch (error.response.status) {
          case 404:
            this.emailMessages = [error.response.data.detail];
            this.passwordMessages = [];
            break;
          case 400:
            this.emailMessages = [];
            this.passwordMessages = [error.response.data.detail];
            break;
        }
      });
    },
  },
};
</script>
