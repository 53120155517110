import Base from "./base";

export default class Auth extends Base {
  async auth (username, password) {
    const form = new FormData();
    form.append("username", username);
    form.append("password", password);
    return await this.makePostRequest("/auth/token", form);
  }

  async resetPassword (email) {
    return await this.makePostRequest("/auth/reset-password", { email });
  }

  async changePassword (password, token) {
    return await this.makePostRequest("/auth/change-password", { password, token });
  }

  async sendEmailVerification (username) {
    return await this.makePostRequest("/auth/send-verification-email", { username });
  }

  async verifyEmail (token) {
    return await this.makePostRequest("/auth/verify-email", { token });
  }

  async signup (username, email, password) {
    return await this.makePostRequest("/auth/register", { username, email, password });
  }

  async deleteAccount (){
    return await this.makeDeleteRequest("/auth/delete-account");
  }
}
