export default {
  methods: {
    popupCenter ({ url, title, w, h }) {
      // Fixes dual-screen position                             Most browsers      Firefox
      // const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      // const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
      //
      // const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
      // const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
      //
      // const systemZoom = width / window.screen.availWidth;
      // const left = (width - w) / 2 / systemZoom + dualScreenLeft;
      // const top = (height - h) / 2 / systemZoom + dualScreenTop;
      // const newWindow = window.open(url, title,
      //   `
      // scrollbars=yes,
      // width=${w},
      // height=${h},
      // top=${top},
      // left=${left}
      // `
      // );

      const newWindow = window.open(url, "_blank");
      if (window.focus) { newWindow.focus(); }
    },
    googleLogin () {
      this.popupCenter({ url: this.$config.axios.baseURL + "/auth/login/google", title: "Google Login", w: 500, h: 600 });
      window.addEventListener("message", (event) => {
        if (event.data.token && event.data.username) {
          this.$store.commit("setUser", event.data);
        }
        this.dialog = false;
      });
    },
    twitterLogin () {
      this.popupCenter({ url: this.$config.axios.baseURL + "/auth/login/twitter", title: "Twitter Login", w: 500, h: 600 });
      window.addEventListener("message", (event) => {
        if (event.data.token && event.data.username) {
          this.$store.commit("setUser", event.data);
        }
        this.dialog = false;
      });
    },
  },
};
